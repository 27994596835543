import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
export type LinkButtonRef = HTMLButtonElement | null;
export interface LinkButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  variant?: 'alpha' | 'grey';
  type?: 'button' | 'submit';
  inactive?: boolean;
}
const LinkButton = forwardRef<LinkButtonRef, LinkButtonProps>(({
  className,
  children,
  variant = 'alpha',
  type = 'button',
  inactive,
  ...props
}, ref) => {
  const componentClasses = classNames(styles.linkButton, {
    [styles.linkButtonGrey]: variant === 'grey',
    [styles.linkButtonInactive]: inactive
  }, className);
  return <button ref={ref} className={componentClasses} type={type} disabled={inactive} {...props}>
        {children}
      </button>;
});
export default LinkButton;