import { getSelectorsByUserAgent } from 'react-device-detect'

const getDeviceTypeFromRequest = (req: {
  headers: { 'user-agent'?: string }
}) => {
  const { headers } = req
  const userAgent = headers['user-agent'] || ''

  const deviceSelectors = getSelectorsByUserAgent(userAgent) || {}

  const {
    isMobile = false,
    isMobileOnly = false,
    isTablet = false,
  } = deviceSelectors

  return {
    isMobile: Boolean(isMobile),
    isMobileOnly: Boolean(isMobileOnly),
    isTablet: Boolean(isTablet),
  }
}

export default getDeviceTypeFromRequest
